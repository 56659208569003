@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.css";
@import "src/variables";

body {
  height: 100vh;
  background-origin: content-box;
  background: no-repeat fixed center;
  background-color: $anim-bg;
  margin: 0;
  & {
    ::-webkit-scrollbar {
      width: 1.1em;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
      border: 4.5px solid transparent;
      box-shadow: inset 2px 1px 2px hsla(0, 0%, 100%, 0.5), inset -3px -7px 5px rgba(75, 75, 75, 0.5);
    }
  }
  .custom-dropdown-panel.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    border-radius: 20px;
    height: 8px !important;
    background: linear-gradient(180deg, rgba(94, 94, 94, 0.1) 0%, rgba(255, 251, 251, 0.1) 47.81%, rgba(94, 94, 94, 0.18) 100%) padding-box;
    border: 4.5px solid transparent;
    box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.5), inset -2px -2px 2px rgba(0, 0, 0, 0.5);
  }
  .custom-dropdown-panel.p-dropdown-items-wrapper::-webkit-scrollbar-track {
    border-radius: 0 20px  20px 0;
    background-color: rgba(33, 33, 33, 0.4);
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 4px 0px inset, rgba(0, 0, 0, 0.08) 1px 1px 4px 0px inset, rgba(255, 255, 255, 0.25) 0px 0px 1px 0px inset, rgba(255, 255, 255, 0.3) 0px 0px 1px 0px inset;
  }
  .custom-dropdown-panel.p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 1.7em;
  }
}

.custom-dropdown-panel.p-dropdown-panel {
  backdrop-filter: blur(100px);
  background: none;
}

.custom-dropdown-panel {
  border-radius: 20px;
}

.carousel-container-providers {
  backdrop-filter: blur(15px);
  padding: 1px 0;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.032) 0%, rgba(94, 94, 94, 0.032) 100%), rgba(255, 255, 255, 0.12);
  background-blend-mode: color-dodge, lighten;
  width: 96%;
  margin: 2em auto 5em;
  border-radius: 50px;
  box-shadow: 0.7px 0.7px rgba(255, 255, 255, 0.4), -0.7px -0.7px rgba(255, 255, 255, 0.6);
  border-image-source: linear-gradient(149.11deg, rgba(255, 255, 255, 0.4) 4.33%, rgba(255, 255, 255, 0.0001) 34.44%, rgba(255, 255, 255, 0.0001) 46.96%, rgba(255, 255, 255, 0.1) 78.54%);
}

.header-buttons-container {
  border-radius: 100px;
  padding-left: 40px;
  padding-right: 40px;
  background: linear-gradient(0deg, rgba(94, 94, 94, 0.032) 0%, rgba(94, 94, 94, 0.032) 100%), rgba(255, 255, 255, 0.12);
  background-blend-mode: color-dodge, lighten;
  box-shadow: 0.7px 0.7px rgba(255, 255, 255, 0.4), -0.7px -0.7px rgba(255, 255, 255, 0.6);
  border-image-source: linear-gradient(149.11deg, rgba(255, 255, 255, 0.4) 4.33%, rgba(255, 255, 255, 0.0001) 34.44%, rgba(255, 255, 255, 0.0001) 46.96%, rgba(255, 255, 255, 0.1) 78.54%);
  font-family: $font-family;
  user-select: none;
  color: $text-color;
  i {
    color: $text-color;
  }
}

.table__action-container {
  backdrop-filter: blur(100px);
  box-shadow: 0.5px 0.5px rgba(255, 255, 255, 0.15), -0.5px -0.5px rgba(255, 255, 255, 0.35);
  background-blend-mode: lighten;
  background: rgba(94, 94, 94, 0.1803921569);
  border: 0.58px solid;
  border-image-source: linear-gradient(149.11deg, rgba(255, 255, 255, 0.4) 4.33%, rgba(255, 255, 255, 0.0001) 34.44%, rgba(255, 255, 255, 0.0001) 46.96%, rgba(255, 255, 255, 0.1) 78.54%);
  min-width: 50px;
  cursor: pointer;
  margin: 7px;
  padding: 10px;
  height: 50px;
  border-radius: 7px;
  user-select: none;
  font-family: $font-family;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  &:hover {
    box-shadow: 0.5px 0.5px rgba(255, 255, 255, 0.15), -0.5px -0.5px rgba(255, 255, 255, 0.35);
    background: radial-gradient(ellipse at top, rgba(255, 255, 255, 0.15), transparent),
    radial-gradient(ellipse at bottom, rgba(255, 255, 255, 0.15), transparent);
  }
}

.table__action-button {
  font-size: 16px;
}

.navigation-img {
  font-size: 20px;
  color: $text-color;
}

.org-add-btn {
  font-size: 18px;
  cursor: pointer;
  color: $text-color;
}

.account-content__item {
  flex-grow: 1;
}

.account-content__item_mobile {
  width: 100%;
  flex-grow: 1;
}

.account-content__item.single {
  margin-right: auto;
}

.account-content {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.account-content__mobile {
  width: 100%;
  justify-content: center; //
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}


.p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:hover,
.p-calendar-w-btn .p-datepicker-trigger.p-button:enabled:active {
  background: none;
}

%background-styles {
  background: rgba(0, 0, 0, 0.1);
  background-blend-mode: color-burn;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;  flex-shrink: 0;
  border-radius: 100px;
}

%hover-effect {
  &:active {
    background-color: rgba(#ffffff, 0.06) !important;
    box-shadow: none !important;
    border: none;
  }

  &:hover {
    background-color: rgba(#ffffff, 0.12);
    box-shadow: 1px 1.5px 4px 0 rgba(0, 0, 0, 0.10) inset,
    1px 1.5px 4px 0 rgba(0, 0, 0, 0.08) inset,
    0px -0.5px 1px 0 rgba(255, 255, 255, 0.25) inset,
    0px -0.5px 1px 0 rgba(255, 255, 255, 0.30) inset,
    0 0 4px 0 rgba(0, 0, 0, 0.08) inset,
    0.5px 1px 1px 0 rgba(208, 208, 208, 0.5) inset;
  }
}

.inner-btn {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgba(94, 94, 94, 0.18);
  background-blend-mode: luminosity;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0.5px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  color: inherit;
  @extend %hover-effect;
  span{
    color: inherit;
  }
}

.inner-btn-wide {
  padding-inline: 20px !important;
}

.text-btn {
  @extend %background-styles;
  height: 44px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  box-shadow: 0px -0.5px 1px 0px rgba(255, 255, 255, 0.30) inset, 0px -0.5px 1px 0px rgba(255, 255, 255, 0.25) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.08) inset, 1px 1.5px 4px 0px rgba(0, 0, 0, 0.10) inset;  font-family: $font-family;
  font-size: 15px;
  color: $text-color;

  button {
    font-size: 15px;
    text-decoration: none;
  }

  .inner-btn {
    border-radius: 20px;
    padding: 7px;
  }

}
