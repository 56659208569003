@import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');

$font-family: var(--font-family);
$box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.3);
$container-length: 1199px;

$basic-light-color: var(--surface-400);
$basic-light-color2: var(--surface-100);
$basic-color: var(--primary-color);
$basic-color-hovered: var(--primary-color-hovered);
$basic-color-additional : var(--primary-color-additional);
$basic-color-opaque: var(--primary-color-opaque);
$header-basic-color: var(--header-primary-color);
$basic-background: var(--basic-background);
$dark-background: var(--dark-background);
$submit-button-background-color: var(--submit-button-background-color);
$submit-button-color: var(--submit-button-color);
$submit-button-hover-color: var(--submit-button-hover-color);
$button-color: var(--primary-color-additional);
$light-grey: var(--gray-200);
$text-color: var(--text-color);
$hovered-text-color: var(--hovered-text-color);
$input-text-color: var(--text-color);
$dark-grey: var(--gray-700);
$sidebar-color: var(--surface-700);
$login-register-pages: linear-gradient(90deg,  var(--indigo-900) 0%,  var(--indigo-600) 57%,  var(--indigo-300) 100%);
$table-data-color: var(--table-data-color);

$anim-layer1-color: var(--anim-layer1-color);
$anim-layer2-color: var(--anim-layer2-color);
$anim-layer3-color: var(--anim-layer3-color);
$anim-layer4-color: var(--anim-layer4-color);
$anim-bg: var(--animation-background);

@media (max-width: 400px) {
  :host ::ng-deep .p-toast {
    width: 300px;
  }
}

:host ::ng-deep .p-button:enabled:focus {
  background: $submit-button-hover-color;
}

:host ::ng-deep .p-button:enabled:hover {
  background: $submit-button-hover-color;
}

:host ::ng-deep .p-datatable .p-datatable-tbody > tr {
  background-color: $dark-background;
  color: $table-data-color;
}

:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
  background-color: $dark-background;
  color: $table-data-color;
}

:host ::ng-deep .p-paginator {
  background-color: $dark-background;
  color: $table-data-color;
}

:host ::ng-deep .p-splitter {
  background-color: $dark-background;
}

:host ::ng-deep .p-treetable .p-treetable-tbody > tr {
  background-color: $dark-background;
}

:host ::ng-deep .p-dropdown-panel {
  background: none;
}

:host ::ng-deep .p-treetable .p-treetable-tfoot > tr > td {
  padding: 0.54rem 1rem;
  background-color: $dark-background !important;
}

:host ::ng-deep .p-treetable .p-treetable-thead > tr > th {
  background-color: $dark-background;
  padding: 0.79rem;
  color: $table-data-color;
}

:host ::ng-deep .p-datatable .p-datatable-tfoot > tr > td {
  background-color: $dark-background;
  color: $table-data-color;
  padding: 0.4rem;
}

:host ::ng-deep .p-treetable .p-treetable-tbody > tr > td {
  color: $table-data-color;
  padding: 0.04rem;
}

:host ::ng-deep .p-datatable > .p-datatable-wrapper {
  background-color: $dark-background;
}

:host ::ng-deep .p-treetable-scrollable-header-box {
  padding-right: unset !important;
}

.animate {
  animation-duration: 1s;
  animation-name: animate-glow;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
}

.submit-button {
  background-color: $submit-button-background-color;
}

:host ::ng-deep .p-button.p-button-success:enabled:hover {
  background: $submit-button-hover-color;
}

:host ::ng-deep .p-button {
  background-color: $submit-button-background-color;
  color: $submit-button-color;
}

:host ::ng-deep .p-dropdown-items-wrapper .p-dropdown-item {
  background-color: $basic-color-additional;
}

:host ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-disabled):hover {
  background-color: $basic-color-hovered;
}

:host ::ng-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: $basic-color-hovered;
}

@keyframes animate-glow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.no-user-selection  {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.popup-form__buttons {
  justify-content: center;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.popup-form__body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 30px;
}
